var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Administrativo")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("General")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Rutas")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"col-6 pt-2 pb-2"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Formulario "+_vm._s(_vm.accion)+" Rutas")])]),_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(1),_c('li',{staticClass:"nav-item"},[(_vm.id != null)?_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Sitios"}},[_vm._v("Sitios y Puntos de Gestión")]):_vm._e()]),_c('li',{staticClass:"nav-item"},[(
                    _vm.id != null &&
                      _vm.$store.getters.can('admin.rutas.edit')
                  )?_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Rutas"}},[_vm._v("Ruta")]):_vm._e()])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Principal"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Nombre Ruta")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nombre),expression:"form.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.nombre.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"text","maxlength":"100","id":"nombre"},domProps:{"value":(_vm.form.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nombre", $event.target.value)}}})]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"codigo"}},[_vm._v("Código")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.codigo),expression:"form.codigo"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.codigo.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","id":"codigo"},domProps:{"value":(_vm.form.codigo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "codigo", $event.target.value)}}})])]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"origen"}},[_vm._v("Origen")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.form.origen_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Origen","label":"nombre","options":_vm.listasForms.origenes},on:{"input":function($event){return _vm.selectOrigen()}},model:{value:(_vm.origen),callback:function ($$v) {_vm.origen=$$v},expression:"origen"}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"destino"}},[_vm._v("Destino")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.form.destino_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Destino","label":"nombre","options":_vm.listasForms.destinos},on:{"input":function($event){return _vm.selectDestino()}},model:{value:(_vm.destino),callback:function ($$v) {_vm.destino=$$v},expression:"destino"}})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"horas_vigencia"}},[_vm._v("Horas de Vigencia")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.horas_vigencia),expression:"form.horas_vigencia"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.horas_vigencia.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"number","id":"horas_vigencia"},domProps:{"value":(_vm.form.horas_vigencia)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "horas_vigencia", $event.target.value)}}})]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tipo_ruta"}},[_vm._v("Tipo de ruta")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_ruta),expression:"form.tipo_ruta"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_ruta.$invalid
                              ? 'is-invalid'
                              : 'is-valid',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_ruta", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipoRutas),function(tipo_ruta){return _c('option',{key:tipo_ruta.numeracion,domProps:{"value":tipo_ruta.numeracion}},[_vm._v(" "+_vm._s(tipo_ruta.descripcion)+" ")])})],2)])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tipo_despachado"}},[_vm._v("Tipo Despachado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_despachado),expression:"form.tipo_despachado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_despachado.$invalid
                              ? 'is-invalid'
                              : 'is-valid',on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_despachado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.limpiarDespachado()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_c('option',{attrs:{"value":"1"}},[_vm._v("ÚNICO DESPACHADO")]),_c('option',{attrs:{"value":"2"}},[_vm._v("MULTIDESPACHADO")])])])]),(_vm.form.tipo_despachado)?_c('div',{staticClass:"col-md-4"},[_c('label',{attrs:{"for":"fecha_expedicion"}},[_vm._v("DESPACHADO A: ")]),(_vm.form.tipo_despachado == 2)?_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.despachados.$invalid ? 'is-invalid' : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Despachados","label":"descripcion","options":_vm.listasForms.despachados,"multiple":""},on:{"input":function($event){return _vm.selectDespachados()}},model:{value:(_vm.despachados),callback:function ($$v) {_vm.despachados=$$v},expression:"despachados"}}):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.despachado_a_id),expression:"form.despachado_a_id"}],staticClass:"form-control form-control-sm",class:[
                          _vm.$v.form.despachado_a_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "despachado_a_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.despachados),function(despachado){return _c('option',{key:despachado.numeracion,domProps:{"value":despachado.numeracion}},[_vm._v(" "+_vm._s(despachado.descripcion)+" ")])})],2)],1):_vm._e(),_c('div',{staticClass:"form-group col-md-2 text-center"},[_c('label',[_vm._v("Redireccionamiento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.redireccionamiento),expression:"form.redireccionamiento"}],staticClass:"form-control form-control-sm p-0",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.redireccionamiento)?_vm._i(_vm.form.redireccionamiento,null)>-1:(_vm.form.redireccionamiento)},on:{"change":function($event){var $$a=_vm.form.redireccionamiento,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "redireccionamiento", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "redireccionamiento", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "redireccionamiento", $$c)}}}})])]),(_vm.form.redireccionamiento == true)?_c('div',{staticClass:"row justify-content-center mt-3 pt-2"},[_c('div',{staticClass:"col-md-8"},[_c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header alert-default-primary pt-2 pb-2"},[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._m(2),_c('div',{staticClass:"card-tools ml-auto"},[(
                                    !_vm.ver_form_ruta &&
                                      _vm.$store.getters.can(
                                        'admin.rutas.createRutaReconocer'
                                      )
                                  )?_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){[
                                      (_vm.formReconocer = {}),
                                      (_vm.ver_form_ruta = true),
                                    ]}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),(_vm.ver_form_ruta)?_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":function($event){[
                                      (_vm.formReconocer = {}),
                                      (_vm.ver_form_ruta = false),
                                    ]}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e()])])]),_c('div',{staticClass:"collapse show",attrs:{"id":"rutas_reconocer"}},[_c('div',{staticClass:"card-body"},[(_vm.ver_form_ruta)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('label',[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formReconocer.nombre),expression:"formReconocer.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.formReconocer.nombre.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.formReconocer.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formReconocer, "nombre", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Tarifa")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formReconocer.tarifa),expression:"formReconocer.tarifa"}],staticClass:"form-control form-control-sm",class:_vm.$v.formReconocer.tarifa.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.formReconocer.tarifa)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formReconocer, "tarifa", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formReconocer.estado),expression:"formReconocer.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.formReconocer.estado.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formReconocer, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Todos...")]),_vm._l((_vm.listasForms.estados),function(estado){return _c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)]),(!_vm.$v.formReconocer.$invalid)?_c('div',{staticClass:"col-md-2 mt-4 pt-2"},[(
                                      _vm.$store.getters.can(
                                        'admin.rutas.saveRutaReconocer'
                                      )
                                    )?_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveRutaReconocer()}}},[_c('small',[_vm._v("Guardar")])]):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"card-body p-0"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm"},[_vm._m(3),_c('tbody',_vm._l((_vm.form.rutas_reconocer),function(rutaRe,indice){return _c('tr',{key:indice},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(rutaRe.nombre)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(rutaRe.valorTarifa)+" ")]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:rutaRe.estado == 1
                                              ? 'badge-success'
                                              : 'badge-warning'},[_vm._v(_vm._s(rutaRe.estado == 1 ? "ACTIVO" : "INACTIVO"))])]),_c('td',{staticClass:"text-center"},[(
                                            _vm.$store.getters.can(
                                              'admin.rutas.editRutaReconocer'
                                            )
                                          )?_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){[
                                              (_vm.ver_form_ruta = true),
                                              _vm.getFormRutaReconocer(rutaRe),
                                            ]}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(
                                            _vm.$store.getters.can(
                                              'admin.rutas.deleteRutaReconocer'
                                            )
                                          )?_c('button',{staticClass:"btn btn-sm bg-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyRutaReconocer(rutaRe.id)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()])])}),0)])])])])])])])]):_vm._e()])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"Sitios"}},[_c('div',{staticClass:"card-body"},[(_vm.id != null)?_c('RutaSitio'):_vm._e()],1)]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"Rutas"}},[_c('div',{staticClass:"card-body"},[_c('RutaDetalle',{ref:"RutaDetalle"})],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")]),(
                    _vm.$store.getters.can('admin.rutas.create') ||
                      _vm.$store.getters.can('admin.rutas.edit')
                  )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]):_vm._e()])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Rutas")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Principal"}},[_vm._v("Principal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-block",attrs:{"data-toggle":"collapse","href":"#rutas_reconocer","aria-expanded":"true","id":"heading-rutas_reconocer"}},[_c('i',{staticClass:"fa fa-chevron-down pull-right"}),_vm._v(" Rutas A Reconocer ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-dark"},[_c('tr',[_c('th',{staticClass:"text-cente col-4"},[_vm._v("Nombre")]),_c('th',{staticClass:"text-center"},[_vm._v("Tarifa")]),_c('th',{staticClass:"text-center"},[_vm._v("Estado")]),_c('th',{staticClass:"text-center col-2"},[_vm._v(" Acción ")])])])
}]

export { render, staticRenderFns }